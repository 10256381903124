let getIngestionRecordsByPage = async (datasetId, pageNumber, recordsPerPage, openApi) => {
  try {
    return await openApi.get(`/datasets/${datasetId}/stats?offset=${(pageNumber) * recordsPerPage}&per_page=${recordsPerPage}`)
  } catch(e) {
    return Promise.reject(e)
  }
}

let state = {
  datasetId: null, 
  paginatedIngestionRecords: {},
  ingestionRecords: null,
  ingestionRecordsLoading: false
}

let getters = {
  paginatedIngestionRecords: (state) => {
    return state.paginatedIngestionRecords
  },
  ingestionRecordsLoading: (state) => {
    return state.ingestionRecordsLoading
  },
  ingestionRecords: (state) => {
    return state.ingestionRecords
  }
}

let actions = {
  getIngestionRecords: async ({state, dispatch}, {datasetId, recordsPerPage, openApi}) => {
    if (state.datasetId === datasetId) return Promise.resolve()
    dispatch('setIngestionRecordsLoading', {value: true, datasetId: datasetId})
    const apiRecordsPerPage = 1000
    try {
      let apiPageNumber = 0
      let localPageNumber = 0
      let count
      do {
        const pageResp = await getIngestionRecordsByPage(datasetId, apiPageNumber, apiRecordsPerPage, openApi)
        const records = JSON.parse(JSON.stringify(pageResp?.data?.records))
          .sort((a, b) => a.snapshot_created_at > b.snapshot_created_at ? 1 : -1)
          .reverse()
        count = records.length
        while (records.length > 0) {
          const currentPageRecords = records.splice(0, recordsPerPage)
          await dispatch('setPaginatedIngestionRecords', {
            datasetId: datasetId,
            pageNumber: localPageNumber,
            records: currentPageRecords
          })
          localPageNumber++
        }
        apiPageNumber++
      } while (count === apiRecordsPerPage)
      await dispatch('setIngestionRecords')
      dispatch('setIngestionRecordsLoading', {value: false})
      return Promise.resolve()
    } catch(e) {
      dispatch('setIngestionRecordsLoading', {value: false})
      return Promise.reject(e)
    }
  },
  setPaginatedIngestionRecords: (store, payload) => {
    store.commit('SET_PAGINATED_INGESTION_RECORDS', payload)
  },
  setIngestionRecords: (store) => {
    store.commit('SET_INGESTION_RECORDS')
  },
  clearIngestionRecords: (store) => {
    store.commit('CLEAR_INGESTION_RECORDS')
  },
  setIngestionRecordsLoading: (store, payload) => {
    store.commit('SET_INGESTION_RECORDS_LOADING', payload)
  }
}

let mutations = {
  ['SET_PAGINATED_INGESTION_RECORDS']: (state, {datasetId, records, pageNumber}) => {
    if (state.datasetId !== datasetId) {
      state.datasetId = datasetId
      state.paginatedIngestionRecords = null
    }
    state.paginatedIngestionRecords[pageNumber] = records
  },
  ['SET_INGESTION_RECORDS']: (state) => {
    state.ingestionRecords = Object.keys(state.paginatedIngestionRecords).reduce((acc, pageNumber) => {
      return acc.concat(state.paginatedIngestionRecords[pageNumber])
    }, [])
  },
  ['SET_INGESTION_RECORDS_LOADING']: (state, payload) => {
    if (payload.datasetId) {
      state.datasetId = payload.datasetId
    }
    state.ingestionRecordsLoading = payload.value
  },
  ['CLEAR_INGESTION_RECORDS']: (state) => {
    state.paginatedIngestionRecords = {}
    state.ingestionRecords = null
    state.datasetId = null
  }
}

export default {
  state,
  getters,
  actions,
  mutations
}