
function convertLocalEditFieldToJson(locEditField, order) {
	let json = {}
	json = {...locEditField}

	if (json.properties && json.type !== 'object') {
		delete json.properties
	}

	if (json.items && json.type !== 'array') {
		delete json.items
	}

	if (json.type === 'object') { // if type was changeed from type = object, remove all nested child properties 
		let objProperties = {}
		if (json.properties && Array.isArray(json.properties)) {
			json.properties.map(property => {
				objProperties[property.name] = convertLocalEditFieldToJson(property, property.order)
			})
		}	
		json.properties = objProperties
	}
	
	if (json.type === 'array') {
		if (json.items?.length > 0) {
			json.items = convertLocalEditFieldToJson(json.items[0], 0)			
		} else {
			json.items = {
				approximate_cardinality: '',
				fieldValues: 'any',
				description: '',
				enum: [],
				is_optional: true,
				is_sellable: true,
				is_sensitive: false,
				name: 'nioArrayItemsField',
				type: 'string',
				isNew: false,
			} 
		}
	}

	json.order = order
	return json
}

// Converting an array of fields into a JSON format that the API expects
function convertPropsArrayToJson(fieldsArr) {
  let obj = {}
  fieldsArr.forEach((el, idx) => {
    obj[el.name] = createFieldJsonObj(el)
  })

  let rtn = { properties: obj }
  return rtn
}

function createFieldJsonObj(el, idx) {
	let obj = {}
	obj.type = el.type
	obj.is_sellable = el.is_sellable
	obj.is_optional = el.is_optional
	obj.is_sensitive = el.is_sensitive
	if (el.required?.length > 0) obj.required = el.required
	if (el.unsellable?.length > 0) obj.unsellable = el.unsellable
  if (el.sensitive?.length > 0) obj.sensitive = el.sensitive
	obj.fieldValues = el.fieldValues
	obj.enum = el.enum
	// Decided to always add order, even though JSON doesnt support to avoid
	//    having to pass file type to this method.
	obj.order = el.order
	if (el.description !== '') obj.description = el.description
	if (el.approximate_cardinality !== '') obj.approximate_cardinality = el.approximate_cardinality
	if (el.properties) {
		obj =  {...obj, ...convertPropsArrayToJson(el.properties)}
	} 

	if (el.items) {
		obj.items = createFieldJsonObj(el.items[0])
	}
	
	return obj
}

// Converting JSON from API to an array for the datasets component
function convertLayerToArray(payload, path) {
	let props = payload.schema
	let datasetType = payload.datasetType
	const keys = props.type === 'array' ? ['items'] : Object.keys(props.properties)
  let clms = keys.map(el => {
		let tmpElement = props.type === 'array' ? props.items : props.properties[el]
		if (tmpElement[0]) tmpElement = tmpElement[0]
	
    let rtn = {
      name: el,
      type: tmpElement.type 
		}
		rtn.order = tmpElement.order
		rtn.description = tmpElement.description ? tmpElement.description : ''
		if (Object.keys(tmpElement).includes('is_optional')) {
			rtn.is_optional = tmpElement.is_optional === true ? true : false
		} else {
			rtn.is_optional = true
		}

    if (Object.keys(tmpElement).includes('is_sensitive')) {
			rtn.is_sensitive = tmpElement.is_sensitive === true ? true : false
		} else {
			rtn.is_sensitive = false
		}

    rtn.is_sellable = tmpElement.is_sellable !== undefined && tmpElement.is_sellable !== null ? tmpElement.is_sellable : true
    // rtn.is_sensitive = tmpElement.is_sensitive !== undefined && tmpElement.is_sensitive !== null ? tmpElement.is_sensitive : true

		if (tmpElement.required?.length > 0) rtn.required = tmpElement.required
		if (tmpElement.unsellable?.length > 0) rtn.unsellable = tmpElement.unsellable
    if (tmpElement.sensitive?.length > 0) rtn.sensitive = tmpElement.sensitive

    if (tmpElement.approximate_cardinality !== undefined && tmpElement.approximate_cardinality !== null) {
      rtn.approximate_cardinality = tmpElement.approximate_cardinality
    } else {
      rtn.approximate_cardinality = ''
    }

    rtn.fieldValues = tmpElement.fieldValues ? tmpElement.fieldValues : 'any'
    rtn.enum = tmpElement.enum ? tmpElement.enum : []
		if (!path) path = ''
		
		rtn.path = `${path}/${el}`

		if (tmpElement.properties && tmpElement.type === 'array') {
			delete tmpElement.properties
		}
		if (tmpElement.properties) {
			rtn.properties = tmpElement.properties ? sortProperties(convertLayerToArray({schema: tmpElement, datasetType: datasetType}, rtn.path).schema, datasetType) : undefined
		} else if (tmpElement.items) {
			rtn.items = sortProperties(
				convertLayerToArray(		
					{
						schema: {properties: {nioArrayItemsField: {...tmpElement.items}}},
						datasetType: datasetType
					},
					rtn.path
				).schema,
				datasetType
			)
		}
		return rtn
	})
	return {
		schema: sortProperties(clms, datasetType),
		datasetType: datasetType
	}
}

function sortProperties(elms, datasetType) {
	if (datasetType === 'flat') {
		return elms.sort((a, b) => (a.order > b.order) ? 1 : -1)
	} else if (datasetType === 'json' || datasetType === 'parquet') {
		return elms.sort((a, b) => (a.name.toLowerCase() > b.name.toLowerCase()) ? 1 : -1)
	}
	return elms
}

function replaceItemInArray(fields, path, newValue) {
  let parts = path.split('/')
	let pt
  parts.splice(0, 1)
  let tmp = {...fields}
  while (parts.length > 1) {
		pt = parts.shift()
		if (tmp.properties) {
			tmp = tmp.properties[pt]
		} else {
			tmp = tmp.items
		}
  }
	pt = parts.shift()
	
	if (tmp.type === 'array') {
		delete tmp.properties
		tmp.items = {...newValue, properties: {}}
	} else {
		if (!tmp.properties) {
			tmp.properties = {}
		} 
	
		if (tmp.properties[pt]) {
			delete tmp.properties[pt]
		}
		tmp.properties[newValue.name] = newValue;	
	}
	
  return fields;
}

function removeItemInArray(fields, path) {
  let parts = path.split('/')
  let pt
  parts.splice(0, 1)
  let tmp = {...fields}
  while (parts.length > 1) {
		pt = parts.shift()
		if (tmp.properties) {
			tmp = tmp.properties[pt]
		} else {
			tmp = tmp.items
		}
  }
	pt = parts.shift()
	const order = tmp.properties[pt].order
	delete tmp.properties[pt]
	
	Object.keys(tmp.properties).forEach(key=> {
		if (tmp.properties[key].order > order) {
			tmp.properties[key].order--
		}
	})

  return fields;
}

function itemByPath(fields, pathArr) {
  let rtn = {}
  let pth = [...pathArr]
  const nm = pth.shift()
  let [item] = fields.filter(fl => {
    return fl.name === nm
  })
  // Checks if we are doing new flow
  if (item === undefined && nm === 'nioNewField') {
		// Default new values
    rtn = {
      approximate_cardinality: '',
      fieldValues: 'any',
      description: '',
      enum: [],
      is_optional: true,
      is_sellable: true,
      is_sensitive: false,
		name: '',
		isNew: true
    }
  } else if (pth.length > 0 && item?.properties?.length > 0) {
		rtn = itemByPath(item.properties, pth)
	} else if (pth.length > 0 && item?.items) {
		rtn = itemByPath(item.items, pth)
  } else {
		// User is choosing to edit
    rtn = item
	}
  return rtn
}

let rtn = []
function flattenPrimaryArray(array, path) {
  // Reset the return value only on first call.
  if (!path) {
    path = ''
    rtn = []
  }
  array.forEach(el => {
    let pt = `${path}/${el.name}`
    let curr = []
    if (el.properties) {
      curr = flattenPrimaryArray(el.properties, pt)
      rtn.concat(...curr)
    }
    return rtn.push({type: el.type, path: pt})
  })

  return rtn
}

function countFieldsFromArray(fieldsArr, totalFields, nestedFields) {
	if (!totalFields) totalFields = 0
	if (!nestedFields) nestedFields = 0

	fieldsArr.forEach(field => {
		const path = field.path.split('/')
		if (path[path.length - 1] !== 'nioArrayItemsField') {
			totalFields ++
		}
		if (path.length > 2 && path[path.length - 1] !== 'nioArrayItemsField') {
			nestedFields++
		}
		if (field.properties && field.properties.length > 0) {
			const childCount = countFieldsFromArray(field.properties, 0, 0)
			totalFields = totalFields + childCount.totalFields
			nestedFields = nestedFields + childCount.nestedFields
		} else if (field.items) {
			const childCount = countFieldsFromArray(field.items, 0, 0)
			totalFields = totalFields + childCount.totalFields
			nestedFields = nestedFields + childCount.nestedFields
		}
	})

	return { totalFields: totalFields, nestedFields: nestedFields}
}

function checkFieldNamesFromJson(properties) {
	let result = {}
	let invalidNames = []

	Object.keys(properties).forEach(propertyName => {
		result[propertyName] = properties[propertyName]
		const nameCheck = checkFieldName(propertyName)
		if (!nameCheck.valid) {
			invalidNames.push({
				origName: propertyName,
				newName: nameCheck.newFieldName
			})
		}
		
		if (properties[propertyName].type === 'object') {
			const childResults = checkFieldNamesFromJson(properties[propertyName].properties)
			invalidNames = [...invalidNames, childResults.invalidNames]
			result[propertyName].properties = childResults.properties
		} else if (properties[propertyName].type === 'array') {
			const childResults = checkFieldNamesFromJson(properties[propertyName].items)
			invalidNames = [...invalidNames, childResults.invalidNames]
			result[propertyName].items = childResults.properties
		}

		if (!nameCheck.valid) {	
			delete result[propertyName]
			result[nameCheck.newFieldName] = properties[propertyName]
		} else {
			
		}
	})

	return {properties: result, invalidNames: invalidNames.flat()}
}

function checkFieldName(fieldName) {
	let valid = true
	let newFieldName = null
	const validFieldNameRegex = new RegExp("^[0-9a-zA-Z_]{1,256}$")
	if (!validFieldNameRegex.test(fieldName)) {
		valid = false
		newFieldName = fixFieldName(fieldName)
	}
	return {
		valid: valid,
		newFieldName: newFieldName
	}
}

function fixFieldName(fieldName) {
	let newFieldName = fieldName.replace(/[^0-9a-zA-Z_]/g, "")
	if (newFieldName.length > 256) {
		newFieldName = newFieldName.substring(0,256)
	}
	return newFieldName
}

export { 
	convertLocalEditFieldToJson, 
	convertLayerToArray, 
	convertPropsArrayToJson, 
	flattenPrimaryArray, 
	itemByPath, 
	replaceItemInArray, 
	removeItemInArray, 
	countFieldsFromArray,
	checkFieldNamesFromJson
}