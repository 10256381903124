
const defaultErrors = [
	{
		name: 'noFields',
		message: 'At least one field is required',
		valid: false,
	},
	{
		name: 'noPrimitiveField',
		message: 'At least one field must be of type String, Long, Double, Booolean or Timepstamptz',
		valid: false
	},
	{
		name: 'noPrimitiveChild',
		message: 'Each field of type Object must have a descendant of type String, Long, Double, Booolean or Timepstamptz',
		valid: true,
		fields: []
	}
]

function validateFields(fieldsArr, errors) {
	if (!errors) {
		errors = JSON.parse(JSON.stringify(defaultErrors))
	}
	fieldsArr.forEach(field => {
		if (errors.find(error => error.name === 'noFields').valid === false && field) {
			errors.find(error => error.name === 'noFields').valid = true
		}
		if (errors.find(error => error.name === 'noPrimitiveField').valid === false && (field.type === 'string' || field.type === 'long' || field.type === 'double' || field.type === 'boolean' || field.type === 'timestamptz')) {
			errors.find(error => error.name === 'noPrimitiveField').valid = true
		}
		if (field.type === 'object' || field.type === 'array') {
			let childValid = true
			if (field.type === 'object') {
				if (!field.properties || field.properties.length < 1) {
					childValid = false
				} else if (field.properties.length > 0 && !hasPrimitiveChild(field.properties, errors)) {
					childValid = false
				}
			} else if (field.type === 'array' && !hasPrimitiveChild(field.items, errors)) {
				childValid = false
			}
			if (!childValid) {
				errors.find(error => error.name === 'noPrimitiveChild').valid = false
				errors.find(error => error.name === 'noPrimitiveChild').fields.push(field.path)
			}
		}	

		if (field.type === 'object' && field.properties && field.properties.length > 0) {
			errors = validateFields(field.properties, errors, true).errors
		} else if (field.type === 'array') {
			errors = validateFields(field.items, errors, true).errors
		}
	})

	function hasPrimitiveChild(childFields, errors) {
			let valid = true
			childFields.forEach(field => {
				let fieldValid = true
				if (field.type === 'object') {
					if (!field.properties || field.properties.length < 1) {
						fieldValid = false
					} else if (field.properties?.length > 0 && !hasPrimitiveChild(field.properties, errors)) {
						fieldValid = false
					}
				} else if (field.type === 'array' && !hasPrimitiveChild(field.items, errors)) {
					fieldValid = false
				}
				if (!fieldValid) {
					errors.find(error => error.name === 'noPrimitiveChild').fields.push(field.path)
					valid = false
				}
			})
			return valid
	}

	return {
		valid: errors.find(error => error.valid === false) === undefined,
		errors: errors.map(error => {
			return {
				name: error.name,
				message: error.message,
				valid: error.valid,
				fields: error.fields ? error.fields.reduce((unique, item) => {
					return (unique.includes(item) ? unique : [...unique, item])
				}, []).reduce((mostNested, item) => {
					if (!error.fields.find(field => field.startsWith(item) && field.length > item.length)) {
						return [...mostNested, item]
					} else {
						return mostNested
					}
				}, []) : undefined
			}
		})
	}
}

export { validateFields }