const NewPage = () => import('@/pages/new/NewPage')
const DatasetsPage = () => import('@/pages/datasets/DatasetsPage')
const DatasetDetailsPage = () => import('@/pages/datasetdetails/DatasetDetails')
const QuickStartPage = () => import('@/pages/quick-start/QuickStartPage')
const SourcesPage = () => import('@/pages/sources/SourcesPage')
const NewSourcePage = () => import('@/pages/sources/new/NewSourcePage')
const Error = () => import('@/pages/error/Error')
export default [
  { path: '/error/:error', name: 'error', component: Error, props: true},
  { path: '/new', name: 'newPage', component: NewPage },
  { path: '/datasets', name: 'datasetsPage', component: DatasetsPage },
  { path: '/datasets/:id', name: 'datasetDetailsPage', component: DatasetDetailsPage, props: true },
  { path: '/quick-start', name: 'quickStartPage', component: QuickStartPage },
  { path: '/sources', name: 'sourcesPage', component: SourcesPage },
  { path: '/sources/new', name: 'newSourcepage', component: NewSourcePage },
]
