import {
  convertPropsArrayToJson,
  convertLayerToArray,
  flattenPrimaryArray,
  itemByPath,
  replaceItemInArray,
	removeItemInArray
} from '@/shared/utils/datasetConversions'
import { validateFields } from '@/shared/utils/validateFields'

const INVALID_PRIMARY = ['double', 'object', 'array']

let state = {
  fields: [],
  eField: undefined,
	pathToField: '',
	fieldsValidation: {
		valid: false, 
		errors: ['Primary field not selected']
	},
	datasetType: 'flat'
}

let getters = {
  getFieldsAsArray: (state) => {
    return state.fields
  },
  getFieldsAsJson: (state) => {
    // Prep output
    return convertPropsArrayToJson(state.fields)
  },
  getPrimaryFieldOptions: (state) => {
    let arr = flattenPrimaryArray(state.fields)
    // console.log('Flat Array: ', arr)
    const prime = arr.filter(fl => {
      return INVALID_PRIMARY.indexOf(fl.type) < 0
    }).map(el => {
      return { name: el.path, value: el.path }
    })
    return prime
  },
  getEditPath: (state) => {
    return state.pathToField
  },
  getEditField: (state) => {
		let dpth = state.pathToField.split('/')
    dpth.splice(0, 1)
		let field = itemByPath(state.fields, dpth)
    return field
	},
	getFieldsValidation: (state) => {
		return state.fieldsValidation
	}
}

let actions = {
	setDatasetType: (store, payload) => {
		store.commit('SET_DATASET_TYPE', payload)
	},
  setFieldsFromJson: (store, payload) => {
		let clms = convertLayerToArray({schema: payload, datasetType: store.state.datasetType}).schema
    store.commit('SET_FIELDS_ARRAY', clms)
	},
	setFieldsFromArray: (store, payload) => {
		store.commit('SET_FIELDS_ARRAY', payload)
	},
  setFieldToEdit: (store, payload) => {
    // Path = '/name/nameNum2/type'
    if (payload !== undefined) {
      store.dispatch('getFieldByPath', payload)
        .then(field => {
					field.isNew = payload.indexOf('nioNewField') > -1 ? true : false
					store.commit('SET_EDIT_FIELD', field)
					store.commit('SET_PATH_TO_FIELD', payload !== undefined ? payload : '')
					
        })
    } else {
			store.commit('SET_EDIT_FIELD', payload)
			store.commit('SET_PATH_TO_FIELD', payload !== undefined ? payload : '')
    }
  },
  getFieldByPath: (store, payload) => {
    let dpth = payload.split('/')
    dpth.splice(0, 1)
		let field = itemByPath(store.getters.getFieldsAsArray, dpth)
    return field
  },
	setEFieldData: (store, payload) => { // this gets called on save
		let locJSON = {...store.getters.getFieldsAsJson}
		replaceItemInArray(locJSON, store.getters.getEditPath, payload)
    store.dispatch('setFieldsFromJson', locJSON)
    store.dispatch('setFieldToEdit', undefined)
  },
  deleteFieldByPath: (store, payload) => {
		// Get IDX for each layer
		let locJSON = {...store.getters.getFieldsAsJson}
    removeItemInArray(locJSON, payload)
    store.dispatch('setFieldsFromJson', locJSON)
  }
}

let mutations = {
	['SET_DATASET_TYPE']: (state, payload) => {
		state.datasetType = payload
	},
  ['SET_FIELDS_ARRAY']: (state, payload) => {
		state.fields = payload
		state.fieldsValidation = validateFields(payload)
  },
  ['SET_PATH_TO_FIELD']: (state, payload) => {
    state.pathToField = payload
  },
  ['SET_EDIT_FIELD']: (state, payload) => {
		state.eField = payload
  }
}

export default {
  state,
  getters,
  actions,
  mutations
}